.LogList table {
	border: 1px solid #bbb;
	padding: 4px;
}

.LogList table td {
	vertical-align: top;
}

.LogList table tr:nth-child(odd) td {
	background-color: #0003;
}

.LogList table tr:nth-child(even) td {
	background-color: #0001;
}
