li.WebsocketConnectionListEntry {
	display: flex;
	flex-direction: row;
	padding: 1ex;
	margin: 0 0 1ex 0;
	justify-content: space-between;
	background-color: var(--table-row-groupheader);
}

li.WebsocketConnectionListEntry:hover {
	background-color: var(--table-row-hover);
}

.WebsocketConnectionListEntry i {
	cursor: default;
	font-style: normal;
	margin-right: 1ex;
}

.WebsocketConnectionListEntry .left {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.WebsocketConnectionListEntry .subtitle {
	display: flex;
	flex-direction: row;
	opacity: 0.75;
}

.WebsocketConnectionListEntry h4 {
	margin: 0;
	padding: 0;
	font-weight: bold;
	width: 100%;
	font-size: inherit;
}

.WebsocketConnectionListEntry .subtitle div {
	margin-right: 1em;
}

.WebsocketConnectionListEntry .subtitle .error {
	color: red;
}

.WebsocketConnectionListEntry .ip {
}

.WebsocketConnectionListEntry .cid {
	text-align: right;
	opacity: 30%;
	flex-grow: 1;
}
