ul.CollectorActivity {
  margin: 0;
  padding: 0;
}

.CollectorActivity li {
  list-style-type: none;
  margin: 1ex;
  padding: 0;

  display: flex;
  flex-direction: row;

}
.CollectorActivity li.new {
  opacity: 0;
}

.CollectorActivity li span {
  margin: 0 0.5ex;
  padding: 0.5ex;
}

.CollectorActivity .code {
  background-color: var(--uq-purple-light);
  color: white;
  border-radius: 5px;
}

@keyframes reveal {
  100% {
    opacity: 1;
    max-height: 5em; /* Any value larger than the element will ever be */
  }
}
